import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import { Title } from '@angular/platform-browser';
import {map, takeUntil} from 'rxjs/operators';
import { TenantThemeService } from 'src/app/data/tenant-theme/tenant-theme.service';
import { SearchFilters, emptyFilters } from '../data/search/search-filters.model';
import { ResourceService } from '../data/resource/resource.service';
import { ResourceSummary } from 'src/app/data/resource/model/summary.model';
import { Router } from '@angular/router';
import quickStartCards from 'src/assets/config/home-quick-start.config.json';
import tenantToolsCards from 'src/assets/config/home-tenant-tools.config.json';
import {UserService} from '../data/user/user.service';
import {TenantToolsCard} from '../data/home-cards/tenant-tools-card.model';
import {QuickStartCard} from '../data/home-cards/quick-start.model';

@Component({
  selector: 'sbdl-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private tenantNameSubscription?: Subscription;
  private userSubscription?: Subscription;
  private plResourcesSubscription?: Subscription;
  private asResourcesSubscription?: Subscription;

  filters: SearchFilters = emptyFilters;
  tenantName$: Observable<string>;
  nameForCard: string;
  logo$: Observable<string>;
  tenantTools: TenantToolsCard[] = tenantToolsCards;
  resourceSummariesPl$ = [];
  resourceSummariesAs$ = [];
  hasScrolled = false;
  hasProfessionalLearningResources = false;
  hasAccessibilityResources = false;
  @ViewChild('scrollerContentPL', { read: ElementRef }) public scrollerContentPL: ElementRef<any>;
  @ViewChild('scrollerContentAS', { read: ElementRef }) public scrollerContentAS: ElementRef<any>;
  @ViewChild('scrollerContentMT', { read: ElementRef }) public scrollerContentMT: ElementRef<any>;
  scrollAmount = 370;
  scrollAmountStart = 370;
  mtScrollAmount = 320;
  loggedIn$: boolean;

  // Add scroll position tracking
  isLeftEndPL = true;
  isRightEndPL = false;
  isLeftEndAS = true;
  isRightEndAS = false;
  isLeftEndMT = true;
  isRightEndMT = false;

  constructor(
    private tenantThemeService: TenantThemeService,
    private titleService: Title,
    private resourceService: ResourceService,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.setTitle();
    this.setTenantTheme();
    this.getTenantName();

    this.updateScrollAmount();

    this.plResourcesSubscription = this.getResourceSummariesForHomePage('pl')
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.resourceSummariesPl$ = data;
        this.hasProfessionalLearningResources = data.length > 0;
        if (this.hasProfessionalLearningResources) {
          this.scrollRight(this.scrollAmountStart, true, 'PL');
          this.hasScrolled = true;
          setTimeout(() => this.checkScrollPosition('PL'), 300);
        }
      });

    this.asResourcesSubscription = this.getResourceSummariesForHomePage('as')
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.resourceSummariesAs$ = data;
        this.hasAccessibilityResources = data.length > 0;
        if (this.hasAccessibilityResources) {
          this.scrollRight(this.scrollAmountStart, true, 'AS');
          this.hasScrolled = true;
          setTimeout(() => this.checkScrollPosition('AS'), 300);
        }
      });

    this.userSubscription = this.userService.authenticated
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => this.loggedIn$ = data);
  }

  private checkScrollPosition(type: 'PL' | 'AS' | 'MT') {
    let element: ElementRef;
    switch (type) {
      case 'PL':
        element = this.scrollerContentPL;
        this.isLeftEndPL = element.nativeElement.scrollLeft <= 0;
        this.isRightEndPL = Math.ceil(element.nativeElement.scrollLeft + element.nativeElement.clientWidth)
          >= element.nativeElement.scrollWidth - 1;
        break;
      case 'AS':
        element = this.scrollerContentAS;
        this.isLeftEndAS = element.nativeElement.scrollLeft <= 0;
        this.isRightEndAS = Math.ceil(element.nativeElement.scrollLeft + element.nativeElement.clientWidth)
          >= element.nativeElement.scrollWidth - 1;
        break;
      case 'MT':
        element = this.scrollerContentMT;
        this.isLeftEndMT = element.nativeElement.scrollLeft <= 0;
        this.isRightEndMT = Math.ceil(element.nativeElement.scrollLeft + element.nativeElement.clientWidth)
          >= element.nativeElement.scrollWidth - 1;
        break;
    }
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    if (event.target.id === 'scrollerContentPL') {
      this.checkScrollPosition('PL');
    } else if (event.target.id === 'scrollerContentAS') {
      this.checkScrollPosition('AS');
    } else if (event.target.id === 'scrollerContentMT') {
      this.checkScrollPosition('MT');
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.tenantNameSubscription) {
      this.tenantNameSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.plResourcesSubscription) {
      this.plResourcesSubscription.unsubscribe();
    }
    if (this.asResourcesSubscription) {
      this.asResourcesSubscription.unsubscribe();
    }
  }

  getTenantName() {
    this.tenantNameSubscription = this.tenantName$
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        this.nameForCard = val;
      });
  }

  setTitle() {
    this.titleService.setTitle('Tools for Teachers - Smarter Balanced');
  }

  setTenantTheme() {
    const currentTenantTheme$ = this.tenantThemeService.currentTenantTheme$;
    this.logo$ = currentTenantTheme$.pipe(
      map(theme => theme.logoUris.full)
    );
    this.tenantName$ = currentTenantTheme$.pipe(
      map(t => t.displayName)
    );
  }

  getResourceSummariesForHomePage(type: string): Observable<ResourceSummary[]> {
    return this.resourceService.getResourceSummariesForHomePage(type);
  }

  private updateScrollAmount(): void {
    const screenWidth = window.screen.width;

    if (screenWidth >= 1200) {
      this.scrollAmount = 370;
      this.scrollAmountStart = 350;
    } else if (screenWidth >= 1024) {
      this.scrollAmount = 370;
      this.scrollAmountStart = 350;
    } else if (screenWidth >= 768) {
      this.scrollAmount = 370;
      this.scrollAmountStart = 0;
    } else if (screenWidth >= 480) {
      this.scrollAmount = 370;
      this.scrollAmountStart = 350;
    } else {
      this.scrollAmount = 335;
      this.scrollAmountStart = 0;
    }
  }

  @HostListener('window:resize', [])
  onWindowResize(): void {
    this.updateScrollAmount();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (!this.hasScrolled && window.scrollY > 0) {
      this.scrollRight(this.scrollAmountStart, true);
      this.hasScrolled = true;
    }
  }

  public scrollRight(scrollAmountNumber: number, short?: boolean, scrollerType?: 'PL' | 'AS' | 'MT'): void {
    let scrollFinal = scrollAmountNumber;
    if (short) {
      scrollFinal = scrollAmountNumber - 47;
    }

    setTimeout(() => {
      if (scrollerType === 'PL' && this.scrollerContentPL && !this.isRightEndPL) {
        const element = this.scrollerContentPL.nativeElement;
        const wouldReachEnd = Math.ceil(element.scrollLeft + element.clientWidth + scrollFinal) >= element.scrollWidth;

        if (wouldReachEnd) {
          element.scrollTo({
            left: element.scrollWidth,
            behavior: 'smooth'
          });
        } else {
          element.scrollTo({
            left: element.scrollLeft + scrollFinal,
            behavior: 'smooth'
          });
        }
        setTimeout(() => this.checkScrollPosition('PL'), 300);
      } else if (scrollerType === 'AS' && this.scrollerContentAS && !this.isRightEndAS) {
        const element = this.scrollerContentAS.nativeElement;
        const wouldReachEnd = Math.ceil(element.scrollLeft + element.clientWidth + scrollFinal) >= element.scrollWidth;

        if (wouldReachEnd) {
          element.scrollTo({
            left: element.scrollWidth,
            behavior: 'smooth'
          });
        } else {
          element.scrollTo({
            left: element.scrollLeft + scrollFinal,
            behavior: 'smooth'
          });
        }
        setTimeout(() => this.checkScrollPosition('AS'), 300);
      } else if (scrollerType === 'MT' && this.scrollerContentMT && !this.isRightEndMT) {
        const element = this.scrollerContentMT.nativeElement;
        const wouldReachEnd = Math.ceil(element.scrollLeft + element.clientWidth + scrollFinal) >= element.scrollWidth;

        if (wouldReachEnd) {
          element.scrollTo({
            left: element.scrollWidth,
            behavior: 'smooth'
          });
        } else {
          element.scrollTo({
            left: element.scrollLeft + scrollFinal,
            behavior: 'smooth'
          });
        }
        setTimeout(() => this.checkScrollPosition('MT'), 300);
      }
    }, 0);
  }

  public scrollLeft(scrollAmountNumber: number, scrollerType?: 'PL' | 'AS' | 'MT'): void {
    if (scrollerType === 'PL' && this.scrollerContentPL && !this.isLeftEndPL) {
      this.scrollerContentPL.nativeElement.scrollTo({
        left: this.scrollerContentPL.nativeElement.scrollLeft - scrollAmountNumber,
        behavior: 'smooth',
      });
      setTimeout(() => this.checkScrollPosition('PL'), 300);
    } else if (scrollerType === 'AS' && this.scrollerContentAS && !this.isLeftEndAS) {
      this.scrollerContentAS.nativeElement.scrollTo({
        left: this.scrollerContentAS.nativeElement.scrollLeft - scrollAmountNumber,
        behavior: 'smooth',
      });
      setTimeout(() => this.checkScrollPosition('AS'), 300);
    } else if (scrollerType === 'MT' && this.scrollerContentMT && !this.isLeftEndMT) {
      this.scrollerContentMT.nativeElement.scrollTo({
        left: this.scrollerContentMT.nativeElement.scrollLeft - scrollAmountNumber,
        behavior: 'smooth',
      });
      setTimeout(() => this.checkScrollPosition('MT'), 300);
    }
  }

  goToSearch(searchString: string): void {
    this.router.navigateByUrl(`/search${searchString}`);
  }

  goToPLLanding(): void {
    this.router.navigate(['/landing/professional']);
  }

  goToASLanding() {
    this.router.navigate(['/landing/accessibility']);
  }

  goToIRLanding() {
    this.router.navigate(['/landing/instructional']);
  }
}
