import {Injectable} from '@angular/core';
import {EMPTY, Observable, of, BehaviorSubject, ReplaySubject} from 'rxjs';
import {expand} from 'rxjs/internal/operators/expand';
import {map} from 'rxjs/internal/operators/map';
import {reduce} from 'rxjs/internal/operators/reduce';
import {DataService} from '../data.service';
import {SearchResults} from './search-results.model';
import {Claim} from '../resource/model/claim.model';
import {Grade} from '../resource/model/grade.model';
import {Subject} from '../resource/model/subject.model';
import {Standard} from '../resource/model/standard.model';
import {Target} from '../resource/model/target.model';
import {emptyFilters, Filter, SearchFilters} from './search-filters.model';
import {ResourceSummary} from '../resource/model/summary.model';
import {
  gradeCodeOrdering,
  gradeCodeShortOrdering,
  resourceTypeCodeOrdering,
  subjectCodeOrdering,
  resourceFieldOrdering
} from '../resource/resource-field-orderings';
import {byOrdering, byString, byStringWithNumber, Comparator, on} from '../../common/sorting/sorting';
import {tap, take} from 'rxjs/operators';
import {Category} from '../resource/model/category.model';
import {Domain} from '../resource/model/domain.model';
import {DCIStrand} from '../resource/model/dciStrand.model';
import {PerformanceExpectation} from '../resource/model/performanceExpectation.model';
import {SortingService} from '../../common/sorting/sorting.service';
import {BumpySearchResults} from './bumpy-search-results.model';
import {HttpParams} from '@angular/common/http';
import {UaagTier} from '../resource/model/uaagTier.model';
import {TenantThemeService} from '../tenant-theme/tenant-theme.service';
import {filter as rxFilter} from 'rxjs/operators';
import {AppliedFilter} from './applied-filter.model';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private tenantCode: string;
  private searchUrl = '/api/search_dl_resources';
  private _pendingRouteParams: any = null;

  // BehaviorSubject to cache and share the default filters
  private defaultFiltersSubject = new BehaviorSubject<SearchFilters>(null);
  public defaultFilters$ = this.defaultFiltersSubject.asObservable().pipe(
    rxFilter(filters => filters !== null) // Only emit when we have filters
  );

  // BehaviorSubject to cache and share the active filters
  private activeFiltersSubject = new BehaviorSubject<SearchFilters>(null);
  public activeFilters$ = this.activeFiltersSubject.asObservable().pipe(
    rxFilter(filters => filters !== null) // Only emit when we have filters
  );

  // ReplaySubject to track applied filters independently of search results
  private appliedFiltersSubject = new ReplaySubject<AppliedFilter[]>(1);
  public appliedFilters$ = this.appliedFiltersSubject.asObservable();

  // Track applied filters internally
  private currentAppliedFilters: AppliedFilter[] = [];

  // BehaviorSubject to cache search results
  private searchResultsSubject = new BehaviorSubject<ResourceSummary[]>([]);
  public searchResults$ = this.searchResultsSubject.asObservable();

  // All the sorts
  private byCode: Comparator<Filter> = on(x => x.code, byString());
  private byResourceTypeCode: Comparator<Filter> = on(x => x.code, byOrdering(resourceTypeCodeOrdering));
  private byResourceFieldOrdering: Comparator<Filter> = on(x => x.code, byOrdering(resourceFieldOrdering));
  private byGradeCode: Comparator<Filter> = on(x => x.code, byOrdering(gradeCodeOrdering));
  private byGradeCodeShort: Comparator<Filter> = on(x => x.grade, byOrdering(gradeCodeShortOrdering));
  private bySubjectCode: Comparator<Filter> = on(x => x.subject, byOrdering(subjectCodeOrdering));
  private byNumber: Comparator<Filter> = on(x => x.number, byStringWithNumber());

  constructor(
    private dataService: DataService,
    private sorting: SortingService,
    private tenantThemeService: TenantThemeService
  ) {
    // Initialize tenant code and load default filters when the tenant theme changes
    this.tenantThemeService.currentTenantTheme$.pipe(
      tap(theme => {
        if (this.tenantCode !== theme.tenantCode) {
          this.tenantCode = theme.tenantCode;
          this.loadDefaultFilters(theme.tenantCode);
        }
      })
    ).subscribe();
  }

  // Method to add an applied filter
  public addAppliedFilter(filter: AppliedFilter): void {
    // Check if filter already exists
    const existingIndex = this.currentAppliedFilters.findIndex(
      f => f.type === filter.type && f.code === filter.code
    );

    if (existingIndex === -1) {
      // Add filter if it doesn't exist
      this.currentAppliedFilters.push(filter);
      this.appliedFiltersSubject.next(this.currentAppliedFilters);
    }
  }

  // Method to remove an applied filter
  public removeAppliedFilter(type: string, code: string): void {
    const index = this.currentAppliedFilters.findIndex(
      f => f.type === type && f.code === code
    );

    if (index !== -1) {
      this.currentAppliedFilters.splice(index, 1);
      this.appliedFiltersSubject.next(this.currentAppliedFilters);
    }
  }

  // Method to clear all applied filters
  public clearAppliedFilters(): void {
    this.currentAppliedFilters = [];
    this.appliedFiltersSubject.next(this.currentAppliedFilters);
  }

  // Method to set applied filters from URL parameters
  public setAppliedFiltersFromParams(params: any): void {
    // Clear existing filters
    this.currentAppliedFilters = [];

    // Update active filters query from params
    if (this.activeFiltersSubject.value) {
      const updatedFilters = {...this.activeFiltersSubject.value};
      // Set query to empty string if not present in params
      updatedFilters.query = params.query || '';
      this.updateActiveFilters(updatedFilters);
    }

    const getFiltersFromCode = (filterType: string, code: string): string => {
      if (this.activeFiltersSubject.value &&
        this.activeFiltersSubject.value[filterType]) {
        const filter = this.activeFiltersSubject.value[filterType].find(f => f.code === code);
        if (filter) {
          return filter.title;
        }
      }

      if (this.defaultFiltersSubject.value &&
        this.defaultFiltersSubject.value[filterType]) {
        const filter = this.defaultFiltersSubject.value[filterType].find(f => f.code === code);
        if (filter) {
          return filter.title;
        }
      }

      // Hardcoded fallbacks for common types
      if (filterType === 'resourceTypes') {
        const resourceTypeMap = {
          cp: 'Connections Playlist',
          ir: 'Instructional',
          pl: 'Professional Learning',
          fs: 'Formative Assessment Strategy',
          as: 'Accessibility Strategy'
        };
        if (resourceTypeMap[code]) {
          return resourceTypeMap[code];
        }
      }

      return code;
    };

    // Process each parameter type that can be a filter
    Object.keys(params).forEach(paramType => {
      // Skip query parameter - it's not a filter
      if (paramType === 'query') { return; }

      // Skip empty parameters
      if (!params[paramType]) { return; }

      // Split comma-separated values
      const codes = params[paramType].split(',');

      codes.forEach(code => {
        // Get title for this code
        const title = getFiltersFromCode(paramType, code);

        // Add to applied filters
        this.currentAppliedFilters.push({
          type: paramType,
          code,
          title,
          additionalType: paramType === 'contentArea' ?
            (code.startsWith('claim') ? 'claim' : 'domain') : undefined
        });
      });
    });

    // Notify subscribers
    this.appliedFiltersSubject.next(this.currentAppliedFilters);
  }

  // Initialize default filters with parameter state
  public initializeFiltersWithParams(params: any): void {
    if (!this.defaultFiltersSubject.value) {
      // If default filters aren't loaded yet, wait for them
      this.defaultFilters$.pipe(
        take(1),
        tap(defaultFilters => {
          // Apply params to the default filters
          const updatedFilters = this.applySelectedStateToFilters(defaultFilters, params);
          // Update the active filters
          this.updateActiveFilters(updatedFilters);
          // Set applied filters from params
          this.setAppliedFiltersFromParams(params);
        })
      ).subscribe();
    } else {
      // Default filters already loaded, just apply params
      const updatedFilters = this.applySelectedStateToFilters(this.defaultFiltersSubject.value, params);
      this.updateActiveFilters(updatedFilters);
      this.setAppliedFiltersFromParams(params);
    }
  }

  public setPendingRouteParams(params: any): void {
    // Make a clean copy of params to avoid reference issues
    const cleanParams = {...params};

    // Explicitly handle empty query strings
    if (cleanParams.hasOwnProperty('query') &&
      (cleanParams.query === '' || cleanParams.query === null || cleanParams.query === undefined)) {
      delete cleanParams.query;
    }

    this._pendingRouteParams = cleanParams;

    // Also set applied filters from these params when they're first set
    if (Object.keys(cleanParams).length > 0) {
      this.setAppliedFiltersFromParams(cleanParams);
    } else {
      // Clear applied filters if there are no params
      this.clearAppliedFilters();
    }
  }

  // For this service, load default filters based on tenant code
  private loadDefaultFilters(tenantCode: string): void {
    if (!tenantCode) {
      tenantCode = 'default';
    }

    this.dataService.get(`/api/search_filters/${tenantCode}`).pipe(
      map((defaultFilters: any) => {
        return this.sortFilters(this.extractDefaultFilters(defaultFilters));
      }),
      tap(filters => {
        // Update the BehaviorSubject with the new filters
        this.defaultFiltersSubject.next(filters);

        // If we don't have active filters yet, set default filters as active
        if (!this.activeFiltersSubject.value) {
          this.activeFiltersSubject.next({...filters});
        }

        // Apply any pending params
        if (this._pendingRouteParams) {
          this.initializeFiltersWithParams(this._pendingRouteParams);
          this._pendingRouteParams = null;
        }
      })
    ).subscribe();
  }

  // Get default filters from the cache or load them if needed
  public getDefaultFilters(): Observable<SearchFilters> {
    // If we already have default filters, return them
    if (this.defaultFiltersSubject.value) {
      return of(this.defaultFiltersSubject.value);
    }

    // Otherwise, wait for them to be loaded
    return this.defaultFilters$.pipe(
      take(1) // Take the first emission and complete
    );
  }

  // Update active filters based on search results
  public updateActiveFilters(filters: SearchFilters): void {
    // Create a copy to avoid reference issues
    const filtersCopy = JSON.parse(JSON.stringify(filters));
    this.activeFiltersSubject.next(filtersCopy);
  }

  // Update search results
  public updateSearchResults(results: ResourceSummary[]): void {
    this.searchResultsSubject.next(results);
  }

  // This is used on the landing pages to reduce filters there to just the type of the landing page
  public getFilterWithParams(params): Observable<SearchResults> {
    return this.dataService.get('/api/search_filters_params', params)
      .pipe(
        map((defaultFilters) => ({
          results: [],
          filters: this.sortFilters(this.extractDefaultFilters(defaultFilters))
        })));
  }

  // This is the most important function, it performs the search and returns the results.

  // This is the most important function, it performs the search and returns the results.
  public searchResourcesPaginate(request: HttpParams): Observable<SearchResults> {
    const currentQuery = request.get('Search_Text') || '';
    // Track current results to append new pages
    let currentResults: ResourceSummary[] = [];

    return this.dataService.get(this.searchUrl, request).pipe(
      tap(nextPage => {
        if (nextPage && nextPage['hydra:member'] && Array.isArray(nextPage['hydra:member'])) {
          const nextPageResults = nextPage['hydra:member'].map(this.resourceSummaryFromJson);
          // Append new page results to current results
          currentResults = [...currentResults, ...nextPageResults];
          // Update UI with accumulated results
          this.updateSearchResults(currentResults);
        }
      }),
      expand(page => {
        // Continue to try to actually fetch the next page as long as we have a next link.
        return this.hasNextPage(page) ?
          this.dataService.get(page['hydra:view']['hydra:next']).pipe(
            // Update the results as each page arrives
            tap(nextPage => {
              if (nextPage && nextPage['hydra:member'] && Array.isArray(nextPage['hydra:member'])) {
                const nextPageResults = nextPage['hydra:member'].map(this.resourceSummaryFromJson);
                // Append new page results to current results
                currentResults = [...currentResults, ...nextPageResults];
                // Update UI with accumulated results
                this.updateSearchResults(currentResults);
              }
            })
          ) :
          EMPTY;
      }),
      reduce(
        (searchFilters, page) => {
          // These two create the filters
          // Note: Don't need to push results here as we're already updating them in the tap operations
          searchFilters.filters.push(...page['hydra:member'].map(this.extractFilters));
          return searchFilters;
        },
        {results: [], filters: [], defaultFilters: []}
      ),
      map((bumpy: BumpySearchResults): SearchResults => {
        // This takes the bumpy results, de-dupes and then sorts the filters
        // Use currentResults instead of bumpy.results since we've been tracking them
        const filteredResults = currentResults;

        // Move the filter processing to a setTimeout to not block rendering
        setTimeout(() => {
          const sortedFilters = this.sortFilters(this.dedupeFilters(bumpy.filters, currentQuery));

          // Make sure we're explicitly setting the query value from the request,
          // not from the current activeFiltersSubject value
          sortedFilters.query = currentQuery;

          // Update the cache with the correct query value
          this.updateActiveFilters(sortedFilters);
        }, 0);

        // Return the results immediately, filters will be updated asynchronously
        return {
          results: filteredResults,
          filters: this.activeFiltersSubject.value || emptyFilters,
        };
      })
    );
  }

  // Add the original order to filters for sorting purposes
  public addOriginalOrderToFilters(filters: any): any {
    if (!filters) { return null; }

    // Create a deep clone of the filters object to avoid mutating the original object
    const newFilters = JSON.parse(JSON.stringify(filters));

    Object.keys(newFilters).forEach(key => {
      // Check if the property is an array and its elements are objects
      if (Array.isArray(newFilters[key]) && newFilters[key].length > 0 && typeof newFilters[key][0] === 'object') {
        newFilters[key].forEach((item: any, index: number) => {
          item.originalOrder = index;
        });
      }
    });

    return newFilters;
  }

  // This gets the params ready to be sent to the API which uses different naming convention.
  public paramsToHttpParams(params: any): HttpParams {
    let httpParams = new HttpParams();
    // This maps to the names in the API
    const keyMapping = {
      grades: 'Grade',
      resourceTypes: 'Resource_Type',
      claims: 'Claim',
      domains: 'Domain',
      dciStrands: 'DciStrand',
      performanceExpectations: 'PerformanceExpectation',
      standards: 'Standard',
      targets: 'Target',
      subjects: 'Subject'
    };
    // Add the query text if there is one
    if (params.query) {httpParams = httpParams.append('Search_Text', params.query); }

    const appendParamsInArrayNotation = (key: string, value: string | string[]) => {
      // Transform the key using the mapping, defaulting to the original key if not found in the mapping
      const transformedKey = keyMapping[key] || key;
      if (typeof value === 'string') {
        value.split(',').forEach(item => {
          httpParams = httpParams.append(`${transformedKey}[]`, item.trim());
        });
      } else if (Array.isArray(value)) {
        value.forEach(item => {
          httpParams = httpParams.append(`${transformedKey}[]`, item);
        });
      }
    };

    // Iterate over all properties in the params object to make them the right format, except 'query'
    Object.keys(params).forEach(key => { if (key !== 'query') {appendParamsInArrayNotation(key, params[key]); }});
    return httpParams;
  }

  private hasNextPage(page: any): boolean {
    return page['hydra:view'].hasOwnProperty('hydra:next');
  }

  // Helper method to apply selected states to filters based on URL params
  public applySelectedStateToFilters(filters: SearchFilters, params: any): SearchFilters {
    if (!filters) { return filters; }

    const result = JSON.parse(JSON.stringify(filters)); // Deep clone

    this.applySelectedToFilterArray(params.resourceTypes, result.resourceTypes);
    this.applySelectedToFilterArray(params.grades, result.grades);
    this.applySelectedToFilterArray(params.subjects, result.subjects);
    this.applySelectedToFilterArray(params.claims, result.claims);
    this.applySelectedToFilterArray(params.targets, result.targets);
    this.applySelectedToFilterArray(params.standards, result.standards);
    this.applySelectedToFilterArray(params.domains, result.domains);
    this.applySelectedToFilterArray(params.dciStrands, result.dciStrands);
    this.applySelectedToFilterArray(params.performanceExpectations, result.performanceExpectations);

    // Update query
    if (params.query) {
      result.query = params.query;
    }

    return result;
  }

  private applySelectedToFilterArray(paramString: string, filterArray: Filter[]): void {
    if (!paramString || !filterArray) { return; }

    const paramCodes = paramString.split(',');
    filterArray.forEach(filter => {
      filter.selected = paramCodes.indexOf(filter.code) !== -1;
    });
  }

  // Json Mappings
  private resourceSummaryFromJson = (json: any): ResourceSummary => {
    return {
      id: json.resourceId,
      properties: {
        authorOrg: json.authorOrganization,
        authors: json.resourceAuthors.map(ra => ra.name),
        categories: json.categories ? json.categories.map(this.categoryFromJson) : [],
        claims: json.claims.map(this.claimFromJson),
        domains: json.domains.map(this.domainFromJson),
        dciStrands: json.dciStrands.map(this.dciStrandFromJson),
        performanceExpectations: json.performanceExpectations.map(this.performanceExpectationFromJson),
        grades: json.grades.map(this.gradeFromJson),
        standards: json.standards.map(this.standardFromJson),
        targets: json.targets.map(this.targetFromJson),
        title: json.title,
        image: json.imagePath,
        lastUpdatedDate: new Date(json.updatedAt),
        subject: this.subjectFromJson(json.subject),
      },
      type: json.dlResourceType.description,
      summary: this.summaryFromJson(json),
      uaagTiers: json.uaagTiers ? json.uaagTiers.map(this.uaagTierFromJson) : [],
      accessibilityCategories: json.accessibilityCategories ? json.accessibilityCategories.map(this.accessibilityCategoriesFromJson) : [],
      professionalLearningCategories:
        json.professionalLearningCategories ? json.professionalLearningCategories.map(this.professionalLearningCategoriesFromJson) : [],
    };
  }

  private summaryFromJson(json: any): string {
    const resourceContentKeys = [
      ['dlResourceContentIr', 'overview'],
      ['dlResourceContentPl', 'overview'],
      ['dlResourceContentCp', 'description'],
      ['dlResourceContentStrategy', 'overview']];

    for (const [key, field] of resourceContentKeys.values()) {
      if (json[key]) {
        return json[key][field];
      }
    }
    return '';
  }

  private categoryFromJson(jsonCategory: any): Category {
    return {
      code: jsonCategory.code,
      description: jsonCategory.description,
      title: jsonCategory.title
    };
  }

  private claimFromJson(jsonClaim: any): Claim {
    return {
      code: jsonClaim.code,
      number: jsonClaim.sequenceNo,
      title: jsonClaim.description,
      description: jsonClaim.description,
      longDescription: jsonClaim.longDescription
    };
  }

  private domainFromJson(jsonDomain: any): Domain {
    return {
      code: jsonDomain.code,
      shortDescription: jsonDomain.shortDescription,
      description: jsonDomain.description
    };
  }

  private dciStrandFromJson(jsonDCIStrand: any): DCIStrand {
    return {
      code: jsonDCIStrand.code,
      shortDescription: jsonDCIStrand.shortDescription,
      description: jsonDCIStrand.description
    };
  }

  private performanceExpectationFromJson(jsonPerformanceExpectation: any): PerformanceExpectation {
    return {
      code: jsonPerformanceExpectation.code,
      shortDescription: jsonPerformanceExpectation.shortDescription,
      description: jsonPerformanceExpectation.description
    };
  }

  private gradeFromJson(jsonGrade: any): Grade {
    return {
      code: jsonGrade.code,
      shortName: jsonGrade.shortDescription,
      longName: jsonGrade.description
    };
  }

  private standardFromJson(jsonStd: any): Standard {
    return {
      code: jsonStd.code,
      title: jsonStd.standard,
      description: jsonStd.description
    };
  }

  private targetFromJson(jsonTgt: any): Target {
    return {
      code: jsonTgt.code,
      number: jsonTgt.number,
      description: jsonTgt.description
    };
  }

  private subjectFromJson(jsonSub: any): Subject {
    if (!jsonSub) {
      return {
        code: null,
        shortName: null,
        fullName: null
      };
    } else {
      return {
        code: jsonSub.code,
        shortName: jsonSub.shortDescription,
        fullName: jsonSub.description
      };
    }
  }

  private uaagTierFromJson(jsonUaagTier: any): UaagTier {
    return {
      code: jsonUaagTier.code,
      description: jsonUaagTier.description,
      title: jsonUaagTier.shortDescription
    };
  }

  private accessibilityCategoriesFromJson(jsonAccessibilityCategories: any): Category {
    return {
      code: jsonAccessibilityCategories.code,
      description: jsonAccessibilityCategories.description,
      title: jsonAccessibilityCategories.shortDescription
    };
  }

  private professionalLearningCategoriesFromJson(jsonProfessionalLearningCategories: any): Category {
    return {
      code: jsonProfessionalLearningCategories.code,
      description: jsonProfessionalLearningCategories.description,
      title: jsonProfessionalLearningCategories.shortDescription
    };
  }

  // The filters are made from what's available from the search
  private extractFilters(res: any): SearchFilters {
    const hasSubject = res.subject;
    const contentAreaClaims = res.claims.map(c => ({
      code: c.code,
      title: c.code.split('-')[0].charAt(0).toUpperCase() + c.code.split('-')[0].slice(1) + ' - ' + c.sequenceNo + ' ' + c.description
    }));
    const contentAreaDomains = res.domains.map(d => ({
      code: d.code,
      title: `Science - ${d.title}`
    }));
    const contentAreaCombined = [...contentAreaClaims, ...contentAreaDomains];
    return {
      query: '', // Always set to empty string, query will be set separately
      resourceTypes: [{code: res.dlResourceType.code, title: res.dlResourceType.description}],
      grades: res.grades.map(g => ({code: g.code, title: g.description})),
      subjects: hasSubject ? [{ code: res.subject.code, title: res.subject.description }] : [],
      claims: res.claims.map(c => ({code: c.code, title: `${c.sequenceNo}: ${c.description}`})),
      domains: res.domains.map(d => ({code: d.code, title: d.description})),
      dciStrands: res.dciStrands.map(ds => ({code: ds.code, title: ds.shortDescription + ' ' + ds.description})),
      performanceExpectations: res.performanceExpectations.map(pe => ({
        code: pe.code,
        title: `${pe.shortDescription} - ${pe.description}`
      })),
      targets: res.targets.map(t => ({
        code: t.code,
        title: `${t.number} - ${t.description} (${t.grade} ${t.claim})`,
        number: t.number, grade: t.gradeShort, subject: t.code.split('-')[0]
      })),
      standards: res.standards.map(s => ({
        code: s.code,
        title: `${s.standard}`
      })),
      contentArea: contentAreaCombined
    };
  }

  // Default filters have a slightly different naming convention: resourceType and subject instead
  // of resourceTypes and subjects.
  public extractDefaultFilters(res: any) {
    const contentAreaClaims = res.claims.map(c => ({
      code: c.code,
      title: c.code.split('-')[0].charAt(0).toUpperCase() + c.code.split('-')[0].slice(1) + ' - ' + c.sequenceNo + ' ' + c.description
    }));
    const contentAreaDomains = res.domains.map(d => ({
      code: d.code,
      title: `Science - ${d.description}`
    }));
    const contentAreaCombined = [...contentAreaClaims, ...contentAreaDomains];
    return {
      query: '', // Always set to empty string, query will be set separately
      resourceTypes: res.resourceType.map(rt => ({code: rt.code, title: rt.description})),
      grades: res.grades.map(g => ({code: g.code, title: g.description})),
      subjects: res.subject.filter(s => s.code !== null && (s.code === 'math' || s.code === 'ela' || s.code === 'sci'))
        .map(s => ({code: s.code, title: s.description})),
      claims: res.claims.map(c => ({code: c.code, title: `${c.subject} - ${c.sequenceNo}. ${c.description}`})),
      domains: res.domains.map(d => ({code: d.code, title: `${d.subject} - ${d.description}`})),
      dciStrands: res.dciStrands.map(dc => ({code: dc.code, title: `${dc.shortDescription} - ${dc.description}`})),
      performanceExpectations: res.performanceExpectations.map(pe => ({
        code: pe.code,
        title: `${pe.shortDescription} - ${pe.description}`
      })),
      targets: res.targets.map(t => ({
        code: t.code,
        title: `${t.number} - ${t.description} (${t.grade} ${t.claim})`,
        number: t.number, grade: t.gradeShort, subject: t.code.split('-')[0]
      })),
      standards: res.standards.map(st => ({
        code: st.code,
        title: `${st.standard}`
      })),
      contentArea: contentAreaCombined
    };
  }

  // Remove duplicate results once we've looked through all the search results
  private dedupeFilters(filtersList: SearchFilters[], origQuery: string): SearchFilters {
    // Use the provided origQuery parameter directly, not from activeFiltersSubject
    const result = {query: origQuery || ''} as SearchFilters;
    const codeSets: Set<string>[] = [];

    // because I don't want to write this all out exactly the same six times
    const filterKeys = ['claims', 'grades', 'resourceTypes', 'standards', 'subjects',
      'targets', 'domains', 'dciStrands', 'performanceExpectations', 'contentArea'];

    for (const key of filterKeys) {
      result[key] = [];
      codeSets[key] = new Set<string>();  // we're going to use sets to speed up the deduping
    }

    // This looks O(n^3) but it's actually O(n) with n = the total # number of
    // filter values across returned resources.
    for (const filters of filtersList) {        // loop over our sets of filters
      for (const key of filterKeys) {           // for every type of filter (claims, grades, etc.)
        for (const value of filters[key]) {     // look at every filter value from the resource
          if (!codeSets[key].has(value.code)) { // if we haven't seen this value before
            result[key].push(value);            // add it to our final result set
            codeSets[key].add(value.code);      // and mark that we've seen it
          }
        }
      }
    }
    return result;
  }

  // This sets all the filter sorts (most of these are at the top of the file)
  private sortFilters(filters: SearchFilters): SearchFilters {
    if (!filters) { return filters; }

    this.sorting.customOrder(filters.domains, resourceFieldOrdering);
    this.sorting.customOrder(filters.dciStrands, resourceFieldOrdering);
    this.sorting.customOrder(filters.performanceExpectations, resourceFieldOrdering);

    const sortedResourceTypes = [...filters.resourceTypes || []].sort(this.byResourceTypeCode);
    const sortedGrades = [...filters.grades || []].sort(this.byGradeCode);
    const sortedSubjects = [...filters.subjects || []].sort(this.byCode);
    const sortedClaims = [...filters.claims || []].sort(this.byCode);
    const sortedDomains = [...filters.domains || []].sort(this.sorting.orderMultiples);
    const sortedDCIStrands = [...filters.dciStrands || []].sort(this.sorting.orderMultiples);
    const sortedPerformanceExpectations = [...filters.performanceExpectations || []].sort(this.sorting.orderMultiples);
    const sortedStandards = [...filters.standards || []].sort(this.byCode);

    // Sorting targets by grade, then by subject, and finally by number
    const sortedTargets = [...filters.targets || []].sort((a, b) => {
      let result = this.byGradeCodeShort(a, b); // First, compare by grade
      if (result === 0) { // If grades are equal
        result = this.bySubjectCode(a, b); // Then, compare by subject
        if (result === 0) { // If subjects are also equal
          result = this.byNumber(a, b); // Then, compare by number
        }
      }
      return result;
    });

    // Return the filters with sorted arrays
    return {
      ...filters,
      resourceTypes: sortedResourceTypes,
      grades: sortedGrades,
      subjects: sortedSubjects,
      claims: sortedClaims,
      domains: sortedDomains,
      dciStrands: sortedDCIStrands,
      performanceExpectations: sortedPerformanceExpectations,
      targets: sortedTargets,
      standards: sortedStandards,
    };
  }
}
