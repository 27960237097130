import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { ResourceSummary } from 'src/app/data/resource/model/summary.model';
import { emptyFilters, SearchFilters } from '../../data/search/search-filters.model';
import { takeUntil, switchMap, tap, finalize } from 'rxjs/operators';
import { SearchService } from '../../data/search/search.service';

@Component({
  selector: 'sbdl-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit, OnDestroy {

  @ViewChild('results', {static: false})
  public resultsElem: ElementRef;
  public allResults: ResourceSummary[] = [];
  public renderedResults: ResourceSummary[] = [];
  public filters: SearchFilters = emptyFilters;
  public defaultFilters: SearchFilters;
  public newSearch = true;
  private readonly destroyed$: Subject<void> = new Subject();
  public isFactorOfThree = false;
  public loading = false;
  public filtersLoading = false;

  constructor(
    private route: ActivatedRoute,
    private searchService: SearchService
  ) {}

  ngOnInit() {
    // Store initial route params
    const initialParams = this.route.snapshot.params;

    // Initialize applied filters from the URL parameters
    if (Object.keys(initialParams).length > 0) {
      this.searchService.setAppliedFiltersFromParams(initialParams);
    }

    // Get default filters from service
    this.searchService.defaultFilters$.pipe(
      takeUntil(this.destroyed$),
      tap(defaultFilters => {
        this.defaultFilters = this.searchService.addOriginalOrderToFilters(defaultFilters);
      })
    ).subscribe();

    // Get active filters from service
    this.searchService.activeFilters$.pipe(
      takeUntil(this.destroyed$),
      tap(activeFilters => {
        this.filters = activeFilters;
      })
    ).subscribe();

    // Listen for search results from service
// Listen for search results from service
    this.searchService.searchResults$.pipe(
      takeUntil(this.destroyed$),
      tap(results => {
        // If this is a new result set (e.g., from a new search)
        if (this.allResults.length === 0 || results.length < this.allResults.length) {
          this.allResults = results || [];
          this.renderedResults = [];
          if (this.allResults.length % 3 === 0) {
            this.isFactorOfThree = true;
          }
          // Start rendering results when they change
          requestAnimationFrame(this.chunkedRender);
        }
        // If these are additional results from pagination (more results than before)
        else if (results.length > this.allResults.length) {
          // Only update allResults - don't touch renderedResults
          this.allResults = results || [];
          // Continue rendering if we're not already
          if (this.renderedResults.length < this.allResults.length) {
            requestAnimationFrame(this.chunkedRender);
          }
        }
      })
    ).subscribe();

    // Handle route parameter changes
    this.route.params.pipe(
      takeUntil(this.destroyed$),
      tap(params => {
        this.loading = true;
        const isDefaultSearchFilter = Object.keys(params).length === 0;
        this.newSearch = isDefaultSearchFilter;

        // Update applied filters from URL params - but don't wait for this
        if (Object.keys(params).length > 0) {
          setTimeout(() => {
            this.searchService.setAppliedFiltersFromParams(params);
          }, 0);
        }
      }),
      switchMap(params => {
        // If no search parameters, just use default filters
        if (this.newSearch) {
          this.loading = false;
          this.allResults = [];
          this.renderedResults = [];
          return this.searchService.getDefaultFilters();
        }

        // Convert params to HTTP params
        const searchParams = this.searchService.paramsToHttpParams(params);

        // Show filters loading state separately
        this.filtersLoading = true;

        // Perform search - separate results loading from filter loading
        return this.searchService.searchResourcesPaginate(searchParams).pipe(
          finalize(() => {
            this.filtersLoading = false;
          })
        );
      })
    ).subscribe(() => {
      this.loading = false;
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  chunkedRender = () => {
    // Optimize rendering by adding more items at once
    const CHUNK_SIZE = 5; // Process more items per frame

    if (this.renderedResults.length < this.allResults.length) {
      const remainingItems = this.allResults.length - this.renderedResults.length;
      const itemsToAdd = Math.min(CHUNK_SIZE, remainingItems);

      // Only add new items - don't replace existing ones
      const newItems = [];
      for (let i = 0; i < itemsToAdd; i++) {
        newItems.push(this.allResults[this.renderedResults.length + i]);
      }

      // Append the new items to the existing rendered results
      this.renderedResults = [...this.renderedResults, ...newItems];

      // Still more to render? Schedule next frame
      if (this.renderedResults.length < this.allResults.length) {
        requestAnimationFrame(this.chunkedRender);
      }
    }
  }

  getResults(): ResourceSummary[] {
    if (!this.renderedResults || this.renderedResults.length === 0) {
      return [];
    }

    if (this.filters?.resourceTypes && this.filters.resourceTypes.length > 0) {
      if (
        this.filters.resourceTypes[0].code === 'as' ||
        this.filters.resourceTypes[0].code === 'fs' ||
        this.filters.resourceTypes[0].code === 'pl'
      ) {
        if (!this.filters.query) {
          return this.renderedResults.sort(this.sortFunc);
        }
      }
    }
    return this.renderedResults;
  }

  sortFunc(a, b) {
    if (a.properties.title < b.properties.title) {
      return -1;
    }
    if (a.properties.title > b.properties.title) {
      return 1;
    }
    return 0;
  }
}
