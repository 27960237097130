import { Component, OnInit, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import { TftErrorService } from '../common/tft-error.service';
import { TftErrorType } from '../common/tft-error-type.enum';
import pagesJson from './pages.config.json';
import {StorageService} from '../common/storage.service';

interface Page {
  href: string;
  pageId: string;
  browserTitle: string;
  html?: string;
  enhancedPage?: string;
}

@Component({
  selector: 'sbdl-page',
  template: `
    <div [id]="currentPage?.pageId" class="pages-main">
      <ng-container *ngIf="currentPage?.html">
        <div [innerHTML]="safePageHtml"></div>
      </ng-container>
      <ng-container #dynamicComponentContainer></ng-container>
    </div>
  `
})
export class PageComponent implements OnInit {
  pages: Page[] = pagesJson;
  currentPage: Page | undefined;
  pageName = '';
  safePageHtml: SafeHtml = '';

  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef })
  container!: ViewContainerRef;

  constructor(
    private route: ActivatedRoute,
    private errorService: TftErrorService,
    private titleService: Title,
    private sanitizer: DomSanitizer,
    private componentFactoryResolver: ComponentFactoryResolver,
    private storageService: StorageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.pageName = params.pageName;
      this.loadPage();
    });

    this.storageService.set('originalUriToReturnTo', '/page/' + this.pageName);
  }

  private async loadPage(): Promise<void> {
    this.currentPage = this.pages.find(page => page.href === this.pageName);

    if (!this.currentPage) {
      this.handlePageNotFound();
      return;
    }

    if (this.currentPage.html) {
      this.safePageHtml = this.sanitizer.bypassSecurityTrustHtml(this.currentPage.html);
    } else if (this.currentPage.enhancedPage) {
      // Wait for view to initialize if needed
      setTimeout(() => this.loadDynamicComponent());
    }

    this.titleService.setTitle(this.currentPage.browserTitle);
  }

  private async loadDynamicComponent(): Promise<void> {
    if (!this.currentPage?.enhancedPage) { return; }

    try {
      // Clear previous component if any
      this.container.clear();

      // Dynamically import the component
      const componentName = `Enhanced${this.capitalizeFirstLetter(this.currentPage.enhancedPage)}Component`;

      // Update the import path to match your file structure
      const module = await import(`./enhanced-pages/enhanced-${this.currentPage.enhancedPage}/enhanced-${this.currentPage.enhancedPage}.component`);

      // Make sure this matches your component's export name exactly
      const component = module[componentName];  // or use module[componentName]

      if (!component) {
        console.error(`Component ${componentName} not found in module`);
        return;
      }

      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
      this.container.createComponent(componentFactory);
    } catch (error) {
      console.error('Error loading dynamic component:', error);
    }
  }

  private capitalizeFirstLetter(firstLetterString: string): string {
    return firstLetterString.charAt(0).toUpperCase() + firstLetterString.slice(1);
  }

  private handlePageNotFound(): void {
    this.errorService.redirectTftError({
      type: TftErrorType.PageNotFound,
      details: 'Page Not Found'
    });
  }

  login() {
    this.router.navigate(['/auth/login'], {
      queryParams: {
        redirectUrl: this.router.url
      }
    });
  }
}
