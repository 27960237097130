<article id="main" class="results-container">
  <sbdl-search
    [filters]="filters"
    [defaultFilters]="defaultFilters"
    [showHeadings]="true"
    [numResults]="allResults?.length || 0"
    [searchPage]="true"
    [InstanceId]="3"
    [filtersLoading]="filtersLoading"
  ></sbdl-search>

  <div class="results-container">

    <div class="all-results">
      <section class="resource-number" *ngIf='!newSearch'>
        <div [ngClass]="{'no-results': !allResults?.length}">

          <ng-container *ngIf="!loading">
            <!-- No results case -->
            <ng-container *ngIf="allResults?.length === 0">
              No results found, try clearing filters or searching for new text.
            </ng-container>
          </ng-container>

            <!-- Single result case -->
            <ng-container *ngIf="renderedResults?.length === 1">
              <span class="resource-count">1</span> Result Showing
            </ng-container>

            <!-- Multiple results case -->
            <ng-container *ngIf="renderedResults?.length > 1">
              <span class="resource-count">{{ renderedResults.length }}</span> Results Showing
            </ng-container>

            <ng-container *ngIf="loading && renderedResults?.length === 0" >
                <span> &nbsp;&nbsp;<i class="fas fa-spinner fa-pulse" aria-label="Loading..."></i> Loading search results</span>
            </ng-container>

            <ng-container *ngIf="loading && renderedResults?.length !== 0" >
              <span>&nbsp;&nbsp;<i class="fas fa-spinner fa-pulse" aria-label="Loading..."></i> Loading results</span>
            </ng-container>
        </div>
      </section>
      <section #results class="search-results">
        <h2 class="offscreen">Search Results</h2><!-- accessibility -->
        <sbdl-search-result-card
          role="group" aria-label="search result"
          *ngFor="let result of getResults(); let i = index"
          [ngClass]="{'odd': i % 2 !== 0}"
          class="result-card"
          [resource]="result"
        ></sbdl-search-result-card>
        <div class="result-card extra-card">&nbsp;</div>
      </section>
    </div>

  </div>
</article>
