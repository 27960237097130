<div class="scroll-container" [ngClass]="containerClass">
  <div class="scroll-button left" [ngClass]="{'disabled': isLeftEnd}" *ngIf="showButtons" (click)="scrollLeft()">
    <div class="scroll-button-circle"><i class="fa fa-chevron-left"></i></div>
  </div>

  <div class="scroll-content" #scrollContent>
    <ng-content></ng-content>
  </div>

  <div class="scroll-button right" [ngClass]="{'disabled': isRightEnd}" *ngIf="showButtons" (click)="scrollRight()">
    <div class="scroll-button-circle"><i class="fa fa-chevron-right"></i></div>
  </div>
</div>
