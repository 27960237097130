<div class="watermarks">
  <img class="waterspot green" src="/assets/images/green-spot-2.png" aria-hidden="true">
  <img class="waterspot yellow" src="/assets/images/yellow-spot-6.png" aria-hidden="true">
  <img class="waterspot blue" src="/assets/images/blue-spot-3.png" aria-hidden="true">
  <img class="waterspot green-mobile" height="98px" src="/assets/images/green-spot-5.png" aria-hidden="true">
  <img class="waterspot yellow-mobile" height="28px" src="/assets/images/yellow-spot-9.png" aria-hidden="true">
  <img class="waterspot blue-mobile" height="46px" src="/assets/images/blue-spot-5.png" aria-hidden="true">
  <img class="waterspot yellow-tablet" src="/assets/images/yellow-spot-13.png" width="59" aria-hidden="true">
</div>
<sbdl-alerts></sbdl-alerts>
<main id="main" class="home-main">

  <img class="logo-full"
       [src]="(logo$ | async) | trustUrl"
       [alt]="((tenantName$ | async) !== null && (tenantName$ | async) !== 'Smarter Balanced') ? (tenantName$ | async) + ' and Tools for Teachers from Smarter Balanced Logo' : 'Tools for Teachers from Smarter Balanced Logo'"
  >
  <h1>
    Find <span class="strong-green">classroom </span>
    <span class="strong-blue">resources</span> you can use today.
  </h1>
  <p>
    Teacher-created, standards-aligned instructional resources (lesson plans) that incorporate the formative assessment
    process with embedded formative assessment, accessibility strategies, and printable worksheets.
  </p>
  <p><sbdl-button (click)="goToSearch('')" [buttonTitle]="'Search Lesson Plans'" class="primary home-search" >
    <i class="far fa-search" aria-hidden="true"></i> SEARCH LESSON PLANS</sbdl-button>
    <sbdl-button (click)="goToIRLanding()"
                 [buttonTitle]="'Learn More'" class="home-learn-more">LEARN MORE</sbdl-button>
  </p>
</main>

<hr/>

<section class="resources-overview home-main">
  <div class="resources-overview-text-cards-container">
    <div class="resources-overview-text section-1">
      <h2>Take action! Leverage <span class="highlight-blue">interim assessment</span> and <span class="highlight-blue">
        observational data</span> to strategically support your students with targeted learning.</h2>
      <p>Augment your curriculum with supplemental, standards-aligned lesson plans that provide targeted support,
        differentiated instruction, and embedded formative assessment strategies.</p>
    </div>
    <div class="resources-overview-cards section-1">
      <div class="resources-overview-card">
        <div class="resources-overview-image"><img src="/assets/images/tenant-tools/InterimConnections.png" alt=""></div>
        <h3 class="resources-overview-title">Interim<br/> Connections Playlists</h3>
        <div class="resources-overview-button"><sbdl-button (click)="goToSearch(';resourceTypes=cp')" [buttonTitle]="'Connections Playlist'"
                                                    class="primary" ><i class="far fa-search" aria-hidden="true"></i> CONNECTIONS PLAYLISTS</sbdl-button></div>
      </div>
      <div class="resources-overview-card resources-overview-wider">
        <div class="resources-overview-image"><img src="/assets/images/tenant-tools/InstructionalResources.png" alt=""></div>
        <h3 class="resources-overview-title">Instructional Resources</h3>
        <div class="resources-overview-button"><sbdl-button (click)="goToSearch(';resourceTypes=ir')"  [buttonTitle]="'Instructional Resources'"
                                                            class="primary" ><i class="far fa-search" aria-hidden="true"></i> INSTRUCTIONAL RESOURCES</sbdl-button></div>
      </div>
    </div>
  </div>
  <div class="resources-overview-text-cards-container">
    <div class="resources-overview-cards section-2">
      <div class="resources-overview-card">
        <div class="resources-overview-image"><img src="/assets/images/tenant-tools/FormativeAssessmentStrategies.png" alt=""></div>
        <h3 class="resources-overview-title">Formative Assessment Strategies</h3>
        <div class="resources-overview-button"><sbdl-button (click)="goToSearch(';resourceTypes=fs')" [buttonTitle]="'Formative Strategies'"
                                                            class="primary" ><i class="far fa-search" aria-hidden="true"></i>  FORMATIVE STRATEGIES</sbdl-button></div>
      </div>
      <div class="resources-overview-card resources-overview-wider">
        <div class="resources-overview-image"><img src="/assets/images/tenant-tools/AccessibilityStrategies.png" alt=""></div>
        <h3 class="resources-overview-title">Accessibility Strategies</h3>
        <div class="resources-overview-button"><sbdl-button (click)="goToSearch(';resourceTypes=as')"  [buttonTitle]="'Accessibility Strategy Resources'" class="primary" ><i class="far fa-search" aria-hidden="true"></i> ACCESSIBILITY STRATEGIES</sbdl-button></div>
      </div>
    </div>
    <div class="resources-overview-text section-2">
      <h2>Power up your lesson plans with resources on
        <span class="highlight-blue">Formative Assessment Strategies</span> and
        <span class="highlight-blue">Accessibility Strategies.</span></h2>
      <p>Adapt your existing lessons using formative assessment techniques and accessibility strategies to effectively
        address diverse learning needs in your classroom.</p>
    </div>
  </div>
</section>
<hr/>

<section class="resource-summary-cards-container professional-learning-container home-main">
  <div class="resource-summary-cards-container-flex">
    <div class="resource-summary-cards-text" [ngClass]="{'full-width': !hasProfessionalLearningResources}">
      <h2 class="scroller-title">Ignite your growth with our engaging professional learning resources</h2>
      <p>From enhanced teaching practices to powerful formative assessment strategies and the strategic use of Smarter Balanced tools, unlock a new level of student engagement and achievement.</p>
      <sbdl-button class="primary button-margin" (click)="goToSearch(';resourceTypes=pl')"><i class="far fa-search"></i> PROFESSIONAL LEARNING</sbdl-button>
      <sbdl-button class="button-margin home-learn-more" (click)="goToPLLanding()">LEARN MORE</sbdl-button>
    </div>
    <div class="resource-summary-cards-scroller" *ngIf="hasProfessionalLearningResources">
      <div class="scroll-button left" (click)="scrollLeft(scrollAmount, 'PL')" [ngClass]="{'disabled': isLeftEndPL}">
        <div class="scroll-button-circle"><i class="fa fa-chevron-left"></i></div>
      </div>
      <div #scrollerContentPL class="resource-summary-cards" (scroll)="onScroll($event)">
        <ng-container *ngIf="resourceSummariesPl$">
          <sbdl-search-result-card
            role="group" aria-label="search result"
            *ngFor="let result of resourceSummariesPl$; let i = index"
            class="result-card home-result-card"
            [resource]="result"
          ></sbdl-search-result-card>
          <div class="scroll-spacer"></div>
        </ng-container>
      </div>
      <div class="scroll-button right" (click)="scrollRight(scrollAmount, false, 'PL')" [ngClass]="{'disabled': isRightEndPL}">
        <div class="scroll-button-circle"><i class="fa fa-chevron-right"></i></div>
      </div>
    </div>
  </div>
</section>
<hr/>

<section class="resource-summary-cards-container accessibility-container home-main">
  <div class="resource-summary-cards-container-flex">
    <div class="resource-summary-cards-scroller" *ngIf="hasAccessibilityResources">
      <div class="scroll-button left" (click)="scrollLeft(scrollAmount, 'AS')" [ngClass]="{'disabled': isLeftEndAS}">
        <div class="scroll-button-circle"><i class="fa fa-chevron-left"></i></div>
      </div>
      <div  #scrollerContentAS class="resource-summary-cards" (scroll)="onScroll($event)">
        <ng-container *ngIf="resourceSummariesAs$">
          <sbdl-search-result-card
            role="group" aria-label="search result"
            *ngFor="let result of resourceSummariesAs$; let i = index"
            class="result-card home-result-card"
            [resource]="result"
          ></sbdl-search-result-card>
          <div class="scroll-spacer"></div>
        </ng-container>
      </div>
      <div class="scroll-button right" (click)="scrollRight(scrollAmount, false, 'AS')" [ngClass]="{'disabled': isRightEndAS}">
        <div class="scroll-button-circle"><i class="fa fa-chevron-right"></i></div>
      </div>
    </div>
    <div class="resource-summary-cards-text accessibility-text" [ngClass]="{'full-width': !hasAccessibilityResources}">
      <h2 class="scroller-title">Find accessibility strategies to meet your students' needs</h2>
      <p>Create an inclusive learning environment that benefits all students by applying a wide range of strategies promoting equal opportunity to engage, learn, and demonstrate their skills and knowledge. Search the full list!</p>
      <sbdl-button class="primary button-margin" (click)="goToSearch(';resourceTypes=as')"><i class="far fa-search"></i> ACCESSIBILITY STRATEGIES</sbdl-button>
      <sbdl-button class="button-margin home-learn-more" (click)="goToASLanding()">LEARN MORE</sbdl-button>
    </div>
  </div>
</section>
<hr/>

<section class="explore-more-tools-card-container">
  <h2 class="more-tools-h2">Unleash Student Potential with our Assessment Tools and Resources</h2>
  <p>Educator-focused tools by Smarter Balanced to support interim assessments, summative assessments, and data-driven teaching & learning.</p>
  <div class="mt-scroller-container">
    <div #scrollerContentMT class="explore-more-tools-cards" (scroll)="onScroll($event)">
      <ng-container *ngFor="let tenantTool of tenantTools">
        <div *ngIf="tenantTool.tenant === nameForCard || tenantTool.tenant === 'any'" role="group" aria-label="explore more" class="three-col-card">
            <div class="more-tools-card">
              <div class="more-tools-image"><img src="{{ tenantTool.imageSource }}" alt=""></div>
              <h3 class="more-tools-title">{{ tenantTool.title }}</h3>
              <div class="more-tools-text">{{ tenantTool.text }}</div>
              <div class="more-tools-button"><sbdl-button [link]=tenantTool.href [buttonTitle]=tenantTool.title
                class="primary" ><i class="far fa-external-link" aria-hidden="true"></i> {{ tenantTool.button }}</sbdl-button></div>
          </div>
        </div>
      </ng-container>

    </div>
    <div class="mt-scroll-button left" (click)="scrollLeft(mtScrollAmount, 'MT')" [ngClass]="{'disabled': isLeftEndMT}">
      <div class="scroll-button-circle"><i class="fa fa-chevron-left"></i></div>
    </div>
    <div class="mt-scroll-button right" (click)="scrollRight(mtScrollAmount, false, 'MT')" [ngClass]="{'disabled': isRightEndMT}">
      <div class="scroll-button-circle"><i class="fa fa-chevron-right"></i></div>
    </div>
  </div>
</section>
<hr/>
