import {
  Component,
  ElementRef,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Observable, Subject, Subscription} from 'rxjs';
import {debounceTime, filter, skip, take, takeUntil} from 'rxjs/operators';
import {emptyFilters, SearchFilters} from '../data/search/search-filters.model';
import {whitelistKeys} from '../common/utils';
import { PopoverComponent } from '../common/controls/popover/popover.component';
import { SearchTextFieldComponent } from '../common/controls/search-text-field/search-text-field.component';
import { LoginWarningService } from './login-warning/login-warning.service';
import { LoginWarningComponent } from './login-warning/login-warning.component';
import { SessionStateKey } from '../common/enums/session-state-key.enum';
import { SearchService } from '../data/search/search.service';
import { Title } from '@angular/platform-browser';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import {TenantThemeService} from '../data/tenant-theme/tenant-theme.service';
import {TenantTheme} from '../data/tenant-theme/tenant-theme.model';
import {FilterCommonWordsService} from '../common/filter-common-words.service';
import {SearchQueryParams} from '../data/search/search-query-params.model';
import {map, tap} from 'rxjs/operators';
import {SearchTextService} from '../data/search/search-text.service';
import {AppliedFilter} from '../data/search/applied-filter.model';

@Component({
  selector: 'sbdl-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})

export class SearchComponent implements OnInit, OnDestroy, OnChanges {

  @Input() filters: SearchFilters = emptyFilters;
  @Input() defaultFilters: SearchFilters;
  @Input() numResults: number;
  @Input() showHeadings = true;
  @Input() searchPage = false;
  @Input() navSearch = false;
  @Input() InstanceId = 0;
  @Input() filtersLoading = false;
  @Output() goToResults = new EventEmitter<boolean>();
  @ViewChild(SearchTextFieldComponent, { static: false }) searchInputTextField: SearchTextFieldComponent;
  @ViewChild('filterContainer') filterContainer!: ElementRef;
  @ViewChild('filterResourceType') filterResourceType!: ElementRef;
  @ViewChild('loginWarningPopover', { static: false }) loginWarningPopover: ElementRef;
  @ViewChild('loginWarning', { static: false }) loginWarning: LoginWarningComponent;

  public params: SearchQueryParams;
  private savedParams: SearchQueryParams;
  public popover: PopoverComponent;
  public filterLoading = false;
  private tenantName: Observable<string>;
  private theme: Observable<TenantTheme>;
  private tenantNameGA: string;
  private destroy$ = new Subject<void>();
  public appliedFilters: AppliedFilter[] = [];

  private routerSubscription: Subscription;
  private loginWarningCloseSubscription: Subscription;
  private searchTimeout: any;
  public combinedOptions: any[] = [];
  public showAdvanced = false;
  private searchTextSubscription: Subscription;
  public typedText: string;
  public selectedFilters = [
    { type: 'resourceTypes', selectedValues: [] },
    { type: 'grades', selectedValues: [] },
    { type: 'targets', selectedValues: [] },
    { type: 'standards', selectedValues: [] },
    { type: 'dciStrands', selectedValues: [] },
    { type: 'performanceExpectations', selectedValues: [] },
    { type: 'subjects', selectedValues: [] },
    { type: 'claims', selectedValues: [] },
    { type: 'domains', selectedValues: [] },
    { type: 'contentArea', selectedValues: [] },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loginWarningService: LoginWarningService,
    private searchService: SearchService,
    private titleService: Title,
    private gtmService: GoogleTagManagerService,
    private tenantThemeService: TenantThemeService,
    private searchFilterService: FilterCommonWordsService,
    private searchTextService: SearchTextService
  ) {
    this.theme = this.tenantThemeService.currentTenantTheme$;
    this.tenantName = this.theme.pipe(map(t => t.displayName));

    this.searchService.appliedFilters$.pipe(
      debounceTime(50), // Debounce to prevent user from making rapid filter changes
      takeUntil(this.destroy$),
      tap(filters => {
        // Update the applied filters
        this.appliedFilters = filters;

        // The selectedFilters structure should be updated based on appliedFilters
        this.updateSelectedFiltersFromApplied(filters);
      })
    ).subscribe();
  }

  ngOnInit() {

    // Store the initial route params for initialization
    const initialParams = this.route.snapshot.params;

    // Set typed text from initial URL params if query exists
    if (initialParams && initialParams.query) {
      this.typedText = initialParams.query;
      // Update search text service to notify other components
      this.searchTextService.updateSearchText(initialParams.query);
    }

    // Initialize service with initial route params
    this.searchService.setPendingRouteParams(initialParams);

    // Get default filters directly from the service
    this.searchService.defaultFilters$.pipe(
      takeUntil(this.destroy$),
      tap(defaultFilters => {
        this.defaultFilters = this.searchService.addOriginalOrderToFilters(defaultFilters);
        // Initialize combined options after default filters are loaded
        this.makeCombinedOptions();

        // Initialize with route params if we have them
        if (Object.keys(initialParams).length > 0) {
          this.searchService.initializeFiltersWithParams(initialParams);
        }
      })
    ).subscribe();

    // Get active filters from the service
    this.searchService.activeFilters$.pipe(
      takeUntil(this.destroy$),
      tap(activeFilters => {
        this.filters = activeFilters;
      })
    ).subscribe();

    // Subscribe to applied filters
    this.searchService.appliedFilters$.pipe(
      takeUntil(this.destroy$),
      tap(filters => {
        // Update the applied filters
        this.appliedFilters = filters;

        // The selectedFilters structure should be updated based on appliedFilters
        this.updateSelectedFiltersFromApplied(filters);
      })
    ).subscribe();

    // Handle route parameter changes
    this.route.params.pipe(
      takeUntil(this.destroy$),
      // Skip the first emission if it's the same as initialParams
      skip(Object.keys(initialParams).length > 0 ? 1 : 0)
    ).subscribe(params => {
      // Update typedText if query param changes in URL
      if (params.query !== undefined) {
        this.typedText = params.query;
        this.searchTextService.updateSearchText(params.query);
      } else if (Object.keys(params).length > 0 && !params.query) {
        // If there are params but no query, clear the typed text
        this.typedText = '';
        this.searchTextService.updateSearchText('');
      }

      // Update the applied filters from URL params
      if (Object.keys(params).length > 0) {
        this.searchService.setAppliedFiltersFromParams(params);
      } else {
        // Clear applied filters if there are no params
        this.searchService.clearAppliedFilters();
      }

      // Update the active filters in the service with query param
      if (this.filters) {
        const updatedFilters = {...this.filters};
        updatedFilters.query = params.query || '';
        this.searchService.updateActiveFilters(updatedFilters);
      }
    });

    // Just for Google Analytics
    this.getTenantName();

    // Resetting the title in the browser, again for Google Analytics
    this.titleService.setTitle('Search: Tools for Teachers - Smarter Balanced');

    // Setting up the params the way we like them
    this.params = this.rectifyParams(this.parseParams(initialParams || {}));

    // If something changes in the params let us know
    this.routerSubscription = this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(() => {
        this.params = this.rectifyParams(this.parseParams(this.route.snapshot.params || {}));
      });

    // Login warning service
    this.loginWarningService.onWarningClosed.subscribe(() => {
      requestAnimationFrame(() => this.searchInputTextField.textFieldRef.nativeElement.focus());
      this.search({ ...this.savedParams }, false);
    });

    // Get the current search text
    this.searchTextSubscription = this.searchTextService.searchText$.subscribe((data) => {
      // Update the value only if it is different to prevent triggering a loop
      if (data !== this.typedText) {
        this.typedText = data;
      }
    });
  }

  // Check if the `filters` input has changed
  ngOnChanges(changes: SimpleChanges) {
    if (changes.filters) {
      // Call the method to match filters with default filters
      this.matchFiltersAndDefaultFilters();
    }

    if (changes.defaultFilters && this.defaultFilters) {
      // If defaultFilters are updated, update the combined options
      this.makeCombinedOptions();
    }
  }

  // Get rid of subscriptions so we don't leak
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }

    if (this.loginWarningCloseSubscription) {
      this.loginWarningCloseSubscription.unsubscribe();
    }

    if (this.searchTextSubscription) {
      this.searchTextSubscription.unsubscribe();
    }
  }

  // Take a params object and filter down it's key discarding anything that is not a valid SearchQueryParam property.
  private parseParams(params: object): SearchQueryParams {
    return whitelistKeys(params as SearchQueryParams,
      [ 'query', 'claims', 'contentArea', 'grades', 'subjects', 'targets', 'standards',
        'resourceTypes', 'domains', 'dciStrands', 'performanceExpectations' ]);
  }

  // Take a valid SearchQueryParams object and make sure it only has correct format
  rectifyParams(params: SearchQueryParams, gtm?): SearchQueryParams {
    if (params.query && gtm) {
      this.gtmEvent(params.query);
    }
    const result = {...params};
    for (const key in result) {
      if (!result[key] || result[key].length === 0) {
        delete result[key];
      }
    }
    return result;
  }

  // This is the meat and potatoes of the whole page, it gets called a lot. It runs on parameters - that's how we
  // store what is going to be searched. All the rest is just for display, ultimately we need the params to be
  // correct if we're going to run the search.
  search(newParams: SearchQueryParams, checkLoginWarning: boolean, source?) {
    // Create a copy of the parameters we'll use for navigation
    const navigateParams = {...this.params};

    // Handle query parameter specifically
    if (newParams.hasOwnProperty('query')) {
      // Update the typed text to match the search parameter
      this.typedText = newParams.query;

      // Update the search text service
      this.searchTextService.updateSearchText(newParams.query);

      // Update the active filters with the new query value (even if empty)
      if (this.filters) {
        const updatedFilters = {...this.filters};
        updatedFilters.query = newParams.query;
        this.searchService.updateActiveFilters(updatedFilters);
      }

      // If query has a value, add it to navigateParams, otherwise remove it
      if (newParams.query) {
        navigateParams.query = newParams.query;
      } else {
        // IMPORTANT: If query is empty, remove it from navigateParams
        delete navigateParams.query;
      }
    } else if (this.filters && this.filters.query) {
      // If newParams doesn't have a query but filters does, use that
      navigateParams.query = this.filters.query;
    }

    // Add the rest of the newParams (except query which we handled above)
    for (const key in newParams) {
      if (key !== 'query') {
        navigateParams[key] = newParams[key];
      }
    }

    // Login warning logic
    let sourceRef: ElementRef;
    switch (source) {
      case 'filterContainer':
        sourceRef = this.filterContainer;
        break;
      case 'filterResourceType':
        sourceRef = this.filterResourceType;
        break;
      default:
        sourceRef = this.searchInputTextField.textFieldRef;
        break;
    }

    if (checkLoginWarning && this.loginWarningService.shouldDisplay(SessionStateKey.searchLoginWarningDisplayed) && !this.navSearch) {
      this.savedParams = { ...navigateParams };
      this.paramsToSearchUrl(this.rectifyParams(navigateParams));
      this.loginWarningService.displayLoginWarning(this.loginWarningPopover, sourceRef,
        SessionStateKey.searchLoginWarningDisplayed);
    } else {
      // Finally we make the call! Search away!
      // Use rectifyParams to clean up the params before navigation
      const cleanParams = this.rectifyParams(navigateParams, true);
      this.router.navigate(['search', cleanParams]);
    }
  }

  // Check which filters are available with current search
  private matchFiltersAndDefaultFilters(): void {
    // Check if both defaultFilters and Filters are there
    if (this.defaultFilters && this.filters) {
      // Go through the defaultFilters object and find each filterType
      Object.keys(this.defaultFilters).forEach(filterType => {
        if (Array.isArray(this.defaultFilters[filterType])) {
          // Go through each part of that filterType and check each entry in the array and assign disabled state
          this.defaultFilters[filterType].forEach(defaultFilter => {
            const filterExistsInFilters = Array.isArray(this.filters[filterType])
              ? this.filters[filterType].some(filterName => filterName.code === defaultFilter.code) : false;
            defaultFilter.disabled = !filterExistsInFilters;
          });
        }
      });
      this.sortDefaultFilters();
      this.makeCombinedOptions();
    }
  }

  //  Sorts filters so available filters are first
  private sortDefaultFilters(): void {
    if (!this.defaultFilters) { return; }

    Object.keys(this.defaultFilters).forEach(filterType => {
      if (Array.isArray(this.defaultFilters[filterType])) {
        if (filterType === 'targets' || filterType === 'standards' || filterType === 'dciStrands' || filterType === 'performanceExpectations') {
          this.defaultFilters[filterType].sort((a, b) => {
            // Set disabled state, treating missing 'disabled' as true
            const aDisabled = a.disabled === undefined ? true : a.disabled;
            const bDisabled = b.disabled === undefined ? true : b.disabled;

            // Sort by disabled
            if (aDisabled !== bDisabled) { return aDisabled ? 1 : -1; }

            // Then sort by originalOrder (set in search-results)
            const aOrder = a.originalOrder === undefined ? Number.MAX_SAFE_INTEGER : a.originalOrder;
            const bOrder = b.originalOrder === undefined ? Number.MAX_SAFE_INTEGER : b.originalOrder;
            return aOrder - bOrder;
          });
        }
      }
    });
  }

  // Special combination for ContentArea
  private makeCombinedOptions() {
    // Reset combined options
    this.combinedOptions = [];

    // Combine claims and domains for one Content Area select
    if (this.defaultFilters?.claims && this.defaultFilters?.domains) {
      // First, add a 'type' property to each item in claims and domains
      const claimsWithTypes = this.defaultFilters.claims.map(claim => ({ ...claim, type: 'claim' }));
      const domainsWithTypes = this.defaultFilters.domains.map(domain => ({ ...domain, type: 'domain' }));

      // Then, combine the arrays
      this.combinedOptions = [...claimsWithTypes, ...domainsWithTypes];
    }
  }

  // For landing page
  onFilterResourcesClick() {
    // this.filterResourcesClicked = true;
    if (this.loginWarningService.shouldDisplay(SessionStateKey.searchLoginWarningDisplayed)) {
      this.loginWarningService.displayLoginWarning(this.loginWarningPopover,
        this.searchInputTextField.textFieldRef, SessionStateKey.searchLoginWarningDisplayed);
    } else {
      this.filterLoading = true;
    }
  }

  // This is for the resource types which are "chips"
  onChipSelectChange(code: string, title: string, filterRef) {
    // Check if this filter is already applied
    const isApplied = this.appliedFilters.some(f =>
      f.type === 'resourceTypes' && f.code === code);

    // Toggle the filter
    if (isApplied) {
      // Remove from applied filters
      this.searchService.removeAppliedFilter('resourceTypes', code);
    } else {
      // Add to applied filters
      this.searchService.addAppliedFilter({
        type: 'resourceTypes',
        code,
        title
      });
    }

    // Check if we have any filters left after this change
    this.searchService.appliedFilters$.pipe(
      take(1) // Take only the next emission with the updated filters
    ).subscribe(filters => {
      // If there are no filters left after this change
      if (filters.length === 0) {
        // Check if there's still a query present
        if (this.typedText && this.typedText.trim() !== '') {
          // If we still have a query, search with just the query
          const searchParams = { query: this.typedText };

          // Update params
          this.params = searchParams;

          // Trigger search with just the query
          this.triggerSearch(searchParams, filterRef);
        } else {
          // No filters AND no query - reset to default state
          // Clear search text
          this.typedText = '';
          this.searchTextService.updateSearchText('');

          // Clear the query in filters
          if (this.filters) {
            const updatedFilters = {...this.filters};
            updatedFilters.query = '';
            this.searchService.updateActiveFilters(updatedFilters);
          }

          // Reset params
          this.params = {};

          // Clear search results (reset to empty array)
          this.searchService.updateSearchResults([]);

          // Reset URL to default search page - with history replacement to avoid
          // a double entry in browser history
          this.router.navigate(['search'], { replaceUrl: true });
        }
      } else {
        // If there are filters left, generate search params from the filters
        const searchParams = this.getSearchParamsFromAppliedFilters();

        // Update this.params before triggering search
        this.params = searchParams;

        // Trigger search with updated params
        this.triggerSearch(searchParams, filterRef);
      }
    });
  }

  // This is for all the other filters
  onSelectDropdownChange(filterType: string, selectedItems: { code: string, title: string, type: string }[], filterRef?) {
    if (filterType === 'contentArea') {
      // For content area, we need to handle claims and domains separately
      if (this.filtersLoading) {
        // Don't process filter changes while filters are still loading
        return;
      }

      // First, remove all existing content area, claims, and domains filters
      this.appliedFilters
        .filter(f => f.type === 'contentArea' || f.type === 'claims' || f.type === 'domains')
        .forEach(f => this.searchService.removeAppliedFilter(f.type, f.code));

      // Then add the new selected items if any
      if (selectedItems && selectedItems.length > 0) {
        const claims = selectedItems.filter(item => item.type === 'claim');
        const domains = selectedItems.filter(item => item.type === 'domain');

        // Add claims
        claims.forEach(claim => {
          // Add to specific filter type (claims)
          this.searchService.addAppliedFilter({
            type: 'claims',
            code: claim.code,
            title: claim.title,
            additionalType: 'claim'
          });

          // Also add to contentArea for URL consistency
          this.searchService.addAppliedFilter({
            type: 'contentArea',
            code: claim.code,
            title: claim.title,
            additionalType: 'claim'
          });
        });

        // Add domains
        domains.forEach(domain => {
          // Add to specific filter type (domains)
          this.searchService.addAppliedFilter({
            type: 'domains',
            code: domain.code,
            title: domain.title,
            additionalType: 'domain'
          });

          // Also add to contentArea for URL consistency
          this.searchService.addAppliedFilter({
            type: 'contentArea',
            code: domain.code,
            title: domain.title,
            additionalType: 'domain'
          });
        });
      }
    } else {
      // For all other filter types, first remove all existing filters of this type
      this.appliedFilters
        .filter(f => f.type === filterType)
        .forEach(f => this.searchService.removeAppliedFilter(filterType, f.code));

      // Then add the new selected items if any
      if (selectedItems && selectedItems.length > 0) {
        selectedItems.forEach(item => {
          this.searchService.addAppliedFilter({
            type: filterType,
            code: item.code,
            title: item.title,
            additionalType: item.type // Usually undefined except for contentArea
          });
        });
      }
    }

    // Check if we have any filters left after this change
    this.searchService.appliedFilters$.pipe(
      take(1) // Take only the next emission with the updated filters
    ).subscribe(filters => {
      // If there are no filters left after this change
      if (filters.length === 0) {
        // Check if there's still a query present
        if (this.typedText && this.typedText.trim() !== '') {
          // If we still have a query, search with just the query
          const searchParams = { query: this.typedText };

          // Update params
          this.params = searchParams;

          // Trigger search with just the query
          this.triggerSearch(searchParams, filterRef);
        } else {
          // No filters AND no query - reset to default state
          // Clear search text
          this.typedText = '';
          this.searchTextService.updateSearchText('');

          // Clear the query in filters
          if (this.filters) {
            const updatedFilters = {...this.filters};
            updatedFilters.query = '';
            this.searchService.updateActiveFilters(updatedFilters);
          }

          // Reset params
          this.params = {};

          // Clear search results (reset to empty array)
          this.searchService.updateSearchResults([]);

          // Reset URL to default search page - with history replacement to avoid
          // a double entry in browser history
          this.router.navigate(['search'], { replaceUrl: true });
        }
      } else {
        // If there are filters left, generate search params from the filters
        const searchParams = this.getSearchParamsFromAppliedFilters();

        // Update this.params before triggering search
        this.params = searchParams;

        // Trigger search with updated params
        this.triggerSearch(searchParams, filterRef);
      }
    });
  }

  private getSearchParamsFromAppliedFilters(): SearchQueryParams {
    const params: SearchQueryParams = {};

    // Group applied filters by type
    const filtersByType = {};

    // First pass - collect all filter types including contentArea
    this.appliedFilters.forEach(filtersel => {
      if (!filtersByType[filtersel.type]) {
        filtersByType[filtersel.type] = [];
      }
      filtersByType[filtersel.type].push(filtersel.code);
    });

    // Convert to search params
    Object.keys(filtersByType).forEach(type => {
      params[type] = filtersByType[type].join(',');
    });

    // Use the current typedText value instead of filters.query
    // Only add the query parameter if typedText has a value
    if (this.typedText && this.typedText.trim() !== '') {
      params.query = this.typedText;
    }
    // Do not add query param if typedText is empty

    return params;
  }

  // This is a helper function, so we can add to the select
  compareWithFn(item1: any, item2: any) {
    return item1 && item2 ? item1.code === item2.code : item1 === item2;
  }

  // This is just a helper to add a pause if we want that
  triggerSearch(searchParams: SearchQueryParams, filterRef?) {
    // Clear any existing timer
    if (this.searchTimeout) { clearTimeout(this.searchTimeout); }
    // Set timer to call this.search
    this.searchTimeout = setTimeout(() => {
      this.search(searchParams, true, filterRef);
    }, 700); // Delay of 700 milliseconds
  }

  removeFilterValue(filterType: string, valueCode: string) {
    // If it's a content area filter, remove from multiple places
    if (filterType === 'contentArea') {
      // Remove the contentArea filter
      this.searchService.removeAppliedFilter('contentArea', valueCode);

      // Determine if it's a claim or domain based on the filter code pattern
      if (valueCode.startsWith('claim') || valueCode.includes('-c')) {
        // It's a claim, remove from claims too
        this.searchService.removeAppliedFilter('claims', valueCode);
      } else {
        // It's a domain, remove from domains too
        this.searchService.removeAppliedFilter('domains', valueCode);
      }
    }
    // Handle removing a claim (should also remove its contentArea counterpart)
    else if (filterType === 'claims') {
      this.searchService.removeAppliedFilter('claims', valueCode);
      this.searchService.removeAppliedFilter('contentArea', valueCode);
    }
    // Handle removing a domain (should also remove its contentArea counterpart)
    else if (filterType === 'domains') {
      this.searchService.removeAppliedFilter('domains', valueCode);
      this.searchService.removeAppliedFilter('contentArea', valueCode);
    }
    else {
      // Remove the specific filter (for non-contentArea, non-claims, non-domains)
      this.searchService.removeAppliedFilter(filterType, valueCode);
    }

    // Subscribe to the applied filters Observable to get the updated filters
    this.searchService.appliedFilters$.pipe(
      take(1) // Take only the next emission (the one with the removed filter)
    ).subscribe(filters => {
      // If there are no filters left after removal
      if (filters.length === 0) {
        // Check if there's still a query present
        if (this.typedText && this.typedText.trim() !== '') {
          // If we still have a query, search with just the query
          const searchParams = { query: this.typedText };

          // Update params
          this.params = searchParams;

          // Trigger search with just the query
          this.triggerSearch(searchParams);
        } else {
          // No filters AND no query - reset to default state
          // Clear search text
          this.typedText = '';
          this.searchTextService.updateSearchText('');

          // Clear the query in filters
          if (this.filters) {
            const updatedFilters = {...this.filters};
            updatedFilters.query = '';
            this.searchService.updateActiveFilters(updatedFilters);
          }

          // Also remove query from params object if it exists
          if (this.params.query) {
            delete this.params.query;
          }

          // Reset params
          this.params = {};

          // Clear search results (reset to empty array)
          this.searchService.updateSearchResults([]);

          // Reset URL to default search page - with history replacement to avoid
          // a double entry in browser history
          this.router.navigate(['search'], { replaceUrl: true });
        }
      } else {
        // If there are filters left, generate search params from the filters
        const searchParams = this.getSearchParamsFromAppliedFilters();

        // Update this.params before triggering search
        this.params = searchParams;

        // Trigger search with updated params
        this.triggerSearch(searchParams);
      }
    });
  }

  private updateSelectedFiltersFromApplied(appliedFilters: AppliedFilter[]): void {
    // Clear existing selections
    this.selectedFilters.forEach(filtersel => {
      filtersel.selectedValues = [];
    });

    // Group applied filters by type
    const filtersByType = {};
    appliedFilters.forEach(filtersel => {
      if (!filtersByType[filtersel.type]) {
        filtersByType[filtersel.type] = [];
      }
      filtersByType[filtersel.type].push(filtersel);
    });

    // Update selectedFilters based on grouped applied filters
    Object.keys(filtersByType).forEach(type => {
      const filterType = this.selectedFilters.find(f => f.type === type);
      if (filterType) {
        filterType.selectedValues = filtersByType[type].map(f => ({
          code: f.code,
          title: f.title,
          type: f.additionalType
        }));
      }
    });
  }

  public clearAllFilters(): void {
    // Check if we want to preserve the query
    const preserveQuery = this.typedText && this.typedText.trim() !== '';
    const queryText = preserveQuery ? this.typedText : '';

    // If not preserving query, clear the search text UI and service
    if (!preserveQuery) {
      this.typedText = '';
      this.searchTextService.updateSearchText('');

      // Also remove query from params object
      if (this.params.query) {
        delete this.params.query;
      }
    }

    // Update the filters with empty or preserved query
    if (this.filters) {
      const updatedFilters = {...this.filters};
      updatedFilters.query = queryText;
      this.searchService.updateActiveFilters(updatedFilters);
    }

    // Clear all applied filters in the service
    this.searchService.clearAppliedFilters();

    // Reset the UI for disabled filters
    this.resetAllDefaultFilters();

    if (preserveQuery) {
      // If preserving query, search with just the query
      const searchParams = { query: queryText };
      this.params = searchParams;
      this.triggerSearch(searchParams);
    } else {
      // If not preserving query, reset everything
      this.params = {};
      this.searchService.updateSearchResults([]);

      // Important: Use replaceUrl to avoid building up history stack
      // and ensure we're passing an empty object instead of params with undefined values
      this.router.navigate(['search'], { replaceUrl: true });
    }
  }

  private resetAllDefaultFilters(): void {
    // Check if defaultFilters is present
    if (this.defaultFilters) {
      // Iterate over each filter type in defaultFilters
      Object.keys(this.defaultFilters).forEach(filterType => {
        // Check if the filterType is an array and iterate over it if true
        if (Array.isArray(this.defaultFilters[filterType])) {
          this.defaultFilters[filterType].forEach(filterName => {
            // Set each filter's disabled property to false
            filterName.disabled = false;
          });
        }
      });

      // Re-sort filters after resetting them
      this.sortDefaultFilters();
    }
  }

  // Helper method to set the url to return to if we set off the login warning
  paramsToSearchUrl(params: any) {
    const parts = Object.keys(params).map(key => `${key}=${params[key]}`);
    return `/search;${parts.join(';')}`;
  }

  // Helper method for display, because we display Resource Types differently, we need to have a way to change the icon
  isResourceTypeSelected(code: string): boolean {
    return this.appliedFilters.some(f =>
      f.type === 'resourceTypes' && f.code === code);
  }

  // Helper method to reduce duplication
  getFilterByName(filterName: string) {
    return this.selectedFilters.find(filterType => filterType.type === filterName);
  }

  // Helper method to hide and show only selects that are available and the label
  containsSci() {
    return this.defaultFilters?.subjects?.some(subject => subject.code === 'sci');
  }

  // Helper method for Applied Filters hide/show
  hasSelectedValues(): boolean {
    return this.appliedFilters.length > 0;
  }

  // Helper method for Applied Filters button titles
  shouldUseCodeForTitle(filterType): boolean {
    switch (filterType) {
      case 'resourceTypes': return false;
      case 'grades': return false;
      case 'targets': return false;
      case 'standards': return false;
      case 'dciStrands': return true;
      case 'performanceExpectations': return true;
      case 'subjects': return true;
      case 'claims': return false;
      case 'domains': return false;
      case 'contentArea': return false;
      default: return false;
    }
  }

  // Helper for mobile-advanced
  showAdvancedClick() {
    this.showAdvanced = !this.showAdvanced;
  }

  // For Google Analytics
  getTenantName() {
    this.tenantName.subscribe(val => this.tenantNameGA = val);
    return this.tenantNameGA;
  }

  // Google Analytics - push to Google Tag Manager
  gtmEvent(searchFull) {
    const searchKeywords = this.searchFilterService.getFilteredWords(searchFull).toString();
    // push GTM data layer with a custom event
    const gtmTag = {
      event: 't4t-search-event',
      t4t_tenant_name: this.getTenantName(),
      t4t_search_keywords: searchKeywords,
      t4t_search_full_search: searchFull,
    };
    this.gtmService.pushTag(gtmTag);
  }

  updateSearchText(text) {
    // Update the search text service with the current text (even if empty)
    this.searchTextService.updateSearchText(text);

    // Update filters directly to keep everything in sync
    if (this.filters) {
      const updatedFilters = {...this.filters};
      updatedFilters.query = text; // Set to empty string if text is empty
      this.searchService.updateActiveFilters(updatedFilters);
    }

    // If text is empty, make sure to update the params object too
    if (!text) {
      // Remove query from params
      if (this.params.query) {
        delete this.params.query;
      }
    } else {
      // Set the query in params
      this.params.query = text;
    }
  }
}
